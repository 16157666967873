(function (App) {
  /**
   * A small helper to harmonize the display of message
   * on the shop front office.
   * @param  {String} message The message to display
   * @param  {String} type    The type of the message (error, success...)
   * @return {void}
   */
  function displayMessage(message, type) {
    const messageType = type || 'info';

    const content = [`<div class="message message--${messageType}">`, message, '</div>'].join(' ');

    App.components.modal.open(content, 'inline');
  }

  /**
   * Dismiss the current message
   * @return {void}
   */
  displayMessage.prototype.close = function () {
    App.components.modal.close();
  };

  // Expose the utility globally
  App.components.displayMessage = displayMessage;
})(App);
